<template>
  <div>
    <div class="detail">
      <div class="detail__word">
        {{ projectName }}
      </div>
      <!--添加术语-->
      <div class="detail__btn" @click="addWord">添加术语</div>
    </div>

    <div class="content-container">
      <div class="head">
        <div class="search">
          <a-input
            type="text"
            allow-clear
            placeholder="搜索源语/目标语"
            v-model="wordName"
            @change="onChangeValue"
            @pressEnter="onSearch"
          />
          <img
            src="../../assets/shop-search.svg"
            alt=""
            @click="onSearch"
            class="curs"
          />
        </div>
        <!--      <div class="head__right">-->
        <!--        <span>语言类型：</span>-->
        <!--        <div class="drop mr40">-->
        <!--          <a-dropdown :trigger="['click']">-->
        <!--            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">-->
        <!--              <div style="min-width: 60px; color: #333333">-->
        <!--                {{ checkTypeName }}-->
        <!--              </div>-->
        <!--              <a-icon-->
        <!--                  type="down"-->
        <!--                  style="color: #0052d9; font-size: 16px; margin-left: 6px"-->
        <!--              />-->
        <!--            </a>-->
        <!--            <a-menu slot="overlay">-->
        <!--              <a-menu-item>-->
        <!--                <a @click="onSort('全部', 'all')">全部</a>-->
        <!--              </a-menu-item>-->
        <!--              <a-menu-item>-->
        <!--                <a @click="onSort('中文-英文', 'zhToEn')">中文-英文</a>-->
        <!--              </a-menu-item>-->
        <!--              <a-menu-item>-->
        <!--                <a @click="onSort('英文-中文', 'enToZh')">英文-中文</a>-->
        <!--              </a-menu-item>-->
        <!--            </a-menu>-->
        <!--          </a-dropdown>-->
        <!--        </div>-->
        <!--      </div>-->
      </div>
      <div class="table">
        <a-table
          :pagination="false"
          :columns="columns"
          :data-source="data"
          class="list__table"
          rowKey="termId"
        >
          <template slot="modifiedTime" slot-scope="text">
            {{ text ? tsFormat(text) : "-" }}
          </template>
          <template slot="action" slot-scope="text, record">
            <a-space style="white-space: nowrap" class="action__word">
              <a @click="editWord(record)">编辑</a>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="openDelete(record)"
              >
                <a class="a-delete">删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </a-table>
      </div>
      <div class="pagination" v-if="total > 0">
        <a-pagination
          v-model="current"
          :total="total"
          :pageSize="15"
          @change="onPage"
        ></a-pagination>
        <span style="margin-left: 20px">共{{ total }}条</span>
      </div>

      <a-modal
        class="addModalStyle"
        :title="modalTitle"
        :visible="addVisible"
        okText="保存"
        cancelText="取消"
        @ok="addTranslateWord"
        @cancel="addVisible = false"
        :maskClosable="false"
      >
        <a-form
          :form="addWordForm"
          @submit="addTranslateWord"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-form-item label="语言方向" labelAlign="left">
            <a-select disabled default-value="zh-en">
              <a-select-option value="zh-en">
                中文-英文
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="源语" labelAlign="left">
            <a-input
              v-decorator="[
                'originalText',
                {
                  rules: [{ required: true, message: '请输入源语' }]
                }
              ]"
            />
          </a-form-item>
          <a-form-item label="目标语" labelAlign="left">
            <a-input
              v-decorator="[
                'translation',
                {
                  rules: [{ required: true, message: '请输入目标语' }]
                }
              ]"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { tsFormat } from "@/components/DateUtils";
import {
  addWords,
  delWords,
  editWords,
  fetchWordsList
} from "@/services/translateService";

const columns = [
  {
    title: "序号",
    dataIndex: "serialNumber",
    key: "serialNumber",
    width: "10%"
  },
  {
    title: "源语",
    dataIndex: "originalText",
    key: "originalText",
    width: "20%"
  },
  {
    title: "目标语",
    dataIndex: "translation",
    key: "translation",
    width: "20%"
  },
  {
    title: "翻译方向",
    dataIndex: "direction",
    key: "direction",
    width: "10%"
  },
  {
    title: "最后修改时间",
    dataIndex: "modifiedTime",
    key: "modifiedTime",
    scopedSlots: { customRender: "modifiedTime" },
    width: "20%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "20%"
  }
];

export default {
  name: "TranslateWords",
  data() {
    return {
      projectId: this.$route.query.id || "", // 空表示公共词典,
      projectName: this.$route.query.name || "公共词典", // 空表示公共词典,
      modalTitle: "添加术语",
      checkTypeName: "全部", // 语言类型 key
      sortType: "Type", // 语言类型 value
      data: [], // 表格数据
      wordName: "", // 搜索的源语value
      columns,
      tsFormat,
      addVisible: false, // 添加弹窗是否可见
      current: 1,
      total: 0,
      addWordForm: this.$form.createForm(this), // 添加术语表单
      editWordInfo: {} // 要编辑的术语信息
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    // 打开添加术语 弹窗
    addWord() {
      this.addWordForm.resetFields();
      this.modalTitle = "添加术语";
      this.addVisible = true;
    },
    // 添加术语 提交
    addTranslateWord() {
      this.addWordForm.validateFields((err, values) => {
        if (!err) {
          const obj = {
            originalText: values.originalText.trim(),
            translation: values.translation.trim(),
            direction: "中文-英文",
            projectId: this.projectId
          };
          if (this.modalTitle === "添加术语") {
            //   新增
            addWords(obj).then(res => {
              if (res.data.code === "SUCCESS") {
                this.addVisible = false;
                this.$message.success("添加成功");
                this.current = 1;
                this.loadList();
              } else {
                this.$message.warning(res.data.errorMsg);
              }
            });
          } else {
            // 编辑
            obj.termId = this.editWordInfo.termId;
            editWords(obj).then(res => {
              if (res.data.code === "SUCCESS") {
                this.addVisible = false;
                this.$message.success("修改成功");
                this.current = 1;
                this.loadList();
              } else {
                this.$message.warning(res.data.errorMsg);
              }
            });
          }
        }
      });
    },
    // 编辑术语
    editWord(record) {
      this.editWordInfo = { ...record };
      this.modalTitle = "编辑术语";
      this.addVisible = true;
      this.$nextTick(() => {
        this.addWordForm.setFieldsValue({
          originalText: record.originalText,
          translation: record.translation
        });
      });
    },
    //确认删除术语
    openDelete(record) {
      delWords(record.termId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.current = 1;
          this.loadList();
        } else {
          this.$message.warning(resp.data.errorMsg);
        }
      });
    },
    //加载术语列表信息
    loadList() {
      const params = {
        keyword: this.wordName,
        pageNum: this.current,
        pageSize: 15,
        projectId: this.projectId,
        direction: "中文-英文"
      };
      fetchWordsList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data.records;
          if (data && data.length > 0) {
            data.forEach(item => {
              item.serialNumber = this.getIndex(
                data,
                item,
                resp.data.data.pageNum
              );
            });
          }
          this.data = data;
          this.total = resp.data.data.total;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    //点击分页
    onPage() {
      this.loadList();
    },
    //点击搜索框的清除图标
    onChangeValue(row) {
      if (row.type === "click") {
        this.onSearch();
      }
    },
    //关键字搜索
    onSearch() {
      this.current = 1;
      this.loadList();
    },
    //右上角分类切换
    onSort(name, sortType) {
      this.checkTypeName = name;
      this.sortType = sortType;
      this.current = 1;
      this.loadList();
    },
    //计算序号
    getIndex(data, item, current) {
      for (let i = 0; i < data.length; i++) {
        if (data[i] === item) {
          return i + 1 + (current - 1) * 15;
        }
      }
    }
  }
};
</script>

<style scoped>
.detail {
  padding: 20px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
}
.detail__word {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.detail__btn {
  background-color: #f96332;
  color: white;
  border-radius: 66px;
  padding: 8px 12px;
  cursor: pointer;
}
.content-container {
  min-width: 1085px;
  box-sizing: border-box;
  padding: 20px;
  min-height: calc(100vh - 102px);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  background-color: #fff;
  margin: 20px;
}
.addModalStyle /deep/ .ant-modal-title {
  text-align: center;
}
/deep/ .ant-table-thead > tr > th {
  background-color: #fafafa !important;
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn-group {
  margin-bottom: 20px;
  padding-left: 20px;
}

.search {
  width: 373px;
  height: 48px;
  border-radius: 409px;
  opacity: 1;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 20px 0 0 10px;
}
.search /deep/ .ant-input {
  outline: none;
  border: none;
  flex-grow: 1;
  background-color: transparent;
}
.search /deep/ .ant-input:hover {
  border-color: transparent;
}
.search /deep/ .ant-input:focus-within {
  border-color: transparent;
}
.search /deep/ .ant-input:focus-visible {
  outline: 0;
}
.search /deep/ .ant-input:focus {
  outline: 0;
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%);
}

/deep/.ant-carousel .slick-dots li {
  margin: 0 8px;
}
/deep/.ant-input-clear-icon {
  font-size: 18px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.ant-pagination-item-active a {
  color: white;
}
/deep/.ant-pagination-prev .ant-pagination-item-link,
/deep/.ant-pagination-next .ant-pagination-item-link {
  border: 0;
}
/deep/.ant-pagination-item-active {
  border-radius: 8px;
  opacity: 1;
  border: 0;
  background: linear-gradient(313deg, #0052d9 0%, #adccff 97%);
}
/deep/.ant-pagination-item {
  border: 0;
}

.list__table {
  margin-bottom: 40px;
}
.action__word span {
  color: #999999;
  font-size: 16px;
}
.share__item__icon img {
  width: 18px;
  height: 18px;
}
</style>
