<template>
  <div class="content-container">
    <div class="table">
      <a-table
        :pagination="false"
        :columns="columns"
        :data-source="data"
        class="list__table"
        rowKey="projectId"
      >
        <template slot="action" slot-scope="text, record">
          <a-space style="white-space: nowrap" class="action__word">
            <a @click="toAddWord(record)">添加术语</a>
          </a-space>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { fetchProjectList } from "@/services/translateService";

const columns = [
  {
    title: "项目名称",
    dataIndex: "projectName",
    key: "projectName",
    width: "25%"
  },
  {
    title: "项目ID",
    dataIndex: "projectId",
    key: "projectId",
    width: "25%"
  },
  {
    title: "术语数量",
    dataIndex: "total",
    key: "total",
    width: "20%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "20%"
  }
];

export default {
  name: "ProjectList",
  data() {
    return {
      data: [], // 表格数据
      columns,
      addWordForm: this.$form.createForm(this) // 添加术语表单
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    // 跳转添加术语列表页
    toAddWord(record) {
      this.$router.push({
        name: "TranslateWords",
        query: {
          id: record.projectId,
          name: record.projectName
        }
      });
    },
    //加载项目列表信息
    loadList() {
      fetchProjectList().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.data = resp.data.data || [];
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  padding: 50px;
}

/deep/ .ant-table-thead > tr > th {
  background-color: #fafafa !important;
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn-group {
  margin-bottom: 20px;
  padding-left: 20px;
}

.search {
  width: 373px;
  height: 48px;
  border-radius: 409px;
  opacity: 1;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 20px 0 0 10px;
}
.search /deep/ .ant-input {
  outline: none;
  border: none;
  flex-grow: 1;
  background-color: transparent;
}
.search /deep/ .ant-input:hover {
  border-color: transparent;
}
.search /deep/ .ant-input:focus-within {
  border-color: transparent;
}
.search /deep/ .ant-input:focus-visible {
  outline: 0;
}
.search /deep/ .ant-input:focus {
  outline: 0;
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%);
}

/deep/.ant-carousel .slick-dots li {
  margin: 0 8px;
}
/deep/.ant-input-clear-icon {
  font-size: 18px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.ant-pagination-item-active a {
  color: white;
}
/deep/.ant-pagination-prev .ant-pagination-item-link,
/deep/.ant-pagination-next .ant-pagination-item-link {
  border: 0;
}
/deep/.ant-pagination-item-active {
  border-radius: 8px;
  opacity: 1;
  border: 0;
  background: linear-gradient(313deg, #0052d9 0%, #adccff 97%);
}
/deep/.ant-pagination-item {
  border: 0;
}

.list__table {
  margin-bottom: 40px;
}
.action__word span {
  color: #999999;
  font-size: 16px;
}
.share__item__icon img {
  width: 18px;
  height: 18px;
}
</style>
