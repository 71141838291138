import { apiClient } from "@/services/HttpService";

// 获取项目列表
export function fetchProjectList() {
  return apiClient.get(`/translation-term/project`);
}

// 查询术语列表
export function fetchWordsList(params) {
  return apiClient.get(
    `/translation-term?projectId=${params.projectId}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&direction=${params.direction}&keyword=${params.keyword}`
  );
}

// 添加术语
export function addWords(params) {
  return apiClient.post(`/translation-term`, params);
}
// 编辑术语
export function editWords(params) {
  return apiClient.put(`/translation-term`, params);
}

// 删除术语
export function delWords(termId) {
  return apiClient.delete(`/translation-term/${termId}`);
}
